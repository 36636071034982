import React, { useRef, useState, useEffect } from 'react';
import './index.scss';
import { getImage, post, get, upload } from '../../../util/request';
// import { post } from '../../util/request';
// import { useHistory } from 'react-router';
// // import { Button, Row, Input, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo, userInfo } from '../../../redux/slice';
import { useTranslation } from 'react-i18next';

// const FormItem = Form.Item

const Updata = ({ value, onChange, type, formRef }) => {
  const { t } = useTranslation();
  const uploadRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(userInfo);

  const [imageUrl, setImageUrl] = useState(null);
  const [imageId, setImageId] = useState(value);
  const [pdfId, setPdfId] = useState(value);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert('max size 5MB');
        return;
      }
      // 检查文件类型  
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const validExtensions = ['png', 'jpg', 'pdf', 'jpeg']; // 可接受的文件类型  
      if (!validExtensions.includes(fileExtension)) {
        alert('only cant upload png,jpg,jpeg,pdf');
        return;
      }
      let params = {
        file: file,
        accessToken: user.accessToken,
        userId: user.userId,
      };
      const data = await upload('/file/blob/' + type, params);
      if (data) {
        onChange(data.fileId);
        if (fileExtension === 'pdf') {
          setPdfId(data.fileId)
          setImageId(data.fileId);
        } else {
          setPdfId()
          setImageId(data.fileId);

        }
        // getImageUrlById(data.fileId);
      }
    }
  };
  useEffect(() => {
    if (imageId) {
      getImageUrlById(imageId);
    }
    formRef?.current
      ?.validateFields()
  }, [imageId]);

  const getImageUrlById = async (id) => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await getImage('/file/blob/' + id, params);
    if (data) {
      const objectURL = URL.createObjectURL(data);
      setImageUrl(objectURL);
    }
  };

  const handleButtonClick = () => {
    console.log(value, type);
    // 模拟点击文件输入元素来打开文件选择对话框
    uploadRef.current.click();
  };
  let setFormData = function (data) {
    let params = new FormData();
    for (let key in data) {
      if (data[key] !== null && data[key] !== undefined) {
        params.append(key, data[key]);
      }
    }
    return params;
  };
  return (
    <div className="updata ">
      <input type="file" onChange={handleFileChange} ref={uploadRef} />

      {pdfId ? (pdfId + '.pdf') : (imageId ? (
        <img src={imageUrl} style={{ maxHeight: '60px', maxWidth: '145px' }} />
      ) : (
        <>
          <div className="icon-box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="32"
              viewBox="0 0 30 32"
              fill="none"
            >
              <path
                d="M21.8062 22.811L16.6996 21.6076L11.9832 14.3867L4.79492 26.7792H24.636L21.8062 22.811Z"
                fill="#5582A0"
              />
              <path
                d="M0.273926 8.20703V31.3007H29.1573V8.20703H0.273926ZM27.7587 29.9021H1.67256V9.60566H27.7587V29.9021Z"
                fill="#5582A0"
              />
              <path
                d="M19.9199 18.0956C21.321 18.0956 22.4569 16.9597 22.4569 15.5585C22.4569 14.1574 21.321 13.0215 19.9199 13.0215C18.5187 13.0215 17.3828 14.1574 17.3828 15.5585C17.3828 16.9597 18.5187 18.0956 19.9199 18.0956Z"
                fill="#5582A0"
              />
              <path d="M27.3356 4.30273H2.09521V5.70137H27.3356V4.30273Z" fill="#5582A0" />
              <path d="M25.4819 0.400391H3.91699V1.79902H25.4819V0.400391Z" fill="#5582A0" />
            </svg>
          </div>

          <p className="file">{t("DragDropYourFileHereOr")}</p>
        </>
      ))}

      <div className="btn" style={{ zIndex: '9' }}>
        <button
          type="button"
          className="filesBtn btn btn-outline-next"
          onClick={() => {
            handleButtonClick();
          }}
        >
          {!imageId ? t("UploadFiles") : t("ReUpload")}
        </button>
      </div>
    </div>
  );
};
export default Updata;
