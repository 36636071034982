import React, { useState, useEffect, useMemo } from 'react';
import * as qs from 'qs';
import './index.scss';
import { get, post } from '../../../util/request';
import i18n, { resources } from '../../../i18n';
import { useHistory } from 'react-router';
import Qs from 'qs';
import { login } from '../../../util/request';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo, userInfo } from '../../../redux/slice';
import { ErrorDialog } from '../../common/errorDialog';
import { useTranslation } from 'react-i18next';
import { Image } from '../../../assets';
import _ from 'lodash';

import { useLocation } from 'react-router-dom';

const baseurl = process.env.REACT_APP_BASEPATH;

const Header = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(userInfo);
  const location = useLocation();
  //获取azure返回的token和objectId
  const locationState = history.location.search;
  const currentPathname = location.pathname;
  const [showMenu, setShowMenu] = useState(true);
  const [rednum, setRednum] = useState(true);

  var obj = Qs.parse(locationState, { ignoreQueryPrefix: true });
  const accessToken = obj.accessToken;
  const objectId = obj.objectId;

  const getCurrentLang = () => {
    const i18nextResList = Object.keys(resources);
    const result = _.intersection(i18n.languages, i18nextResList);
    return result[0];
  };
  const [currentLocal, setCurrentLocal] = React.useState(getCurrentLang());

  // if (!user && accessToken && objectId) {
  //   //登陆操作
  //   handleLogin(accessToken, objectId);
  // }
  // const handleLogin = async (accessToken, objectId) => {
  //   const obj = {
  //     accessToken: accessToken,
  //     objectId: objectId,
  //     deviceId: null,
  //     deviceModel: 'WEB',
  //     deviceToken: null,
  //     entry: 'WEB',
  //     isChina: false,
  //   };
  //   const data = await login('/auth/aad/b2c/login', obj);
  //   if (data) {
  //     dispatch(updateUserInfo(data));

  //     if (data.userType == 'CL') {
  //       goDetail('/Investments');
  //     } else {
  //       goDetail('/viewClient');
  //     }
  //   }
  // };

  const [isButton, setButton] = useState(true);

  const [listData, setListData] = useState([]);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [num, setnum] = useState(null);
  const init = async () => {
    if (!user.accessToken && !user.userId) {
      return;
    }
    console.log(user);
    let params = {
      offset: 'DESC',
      pageSize: 4,
      pageNumber: 0,
      userId: user.userId,
      accessToken: user.accessToken,
      size: 4,
    };
    const data = await get('/notification/list', params);
    if (data) {
      var list = data.content ? data.content : [];
      for (let i = 0; i < list.length; i++) {
        // list[i].deliveryDate
        var year = list[i].deliveryDate.slice(0, 4);
        var mon = list[i].deliveryDate.slice(5, 7);
        var day = list[i].deliveryDate.slice(8, 10);
        var hour = list[i].deliveryDate.slice(11, 13);
        var minute = list[i].deliveryDate.slice(14, 16);
        list[i].deliveryDate = year + '/' + mon + '/' + day + ' ' + hour + ':' + minute;
      }

      setListData(list);
      red();
    }
  };

  const red = async () => {
    let params = {
      offset: 'DESC',
      userId: user.userId,
      accessToken: user.accessToken,
      unread: true,
      pageSize: 1000,
      pageNumber: 0,
      size: 1000,
    };
    const data = await get('/notification/list', params);
    if (data) {
      setRednum(data.content?.length);
    }
  };

  const checkbtn = () => {
    if (isButton) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const gotoinbox = () => {
    setButton(true);
    goDetail('/inbox');
  };

  function goDetail(url) {
    setShowMobileMenu(!showMobileMenu)
    console.log(url);
    history.push({
      pathname: url,
    });
  }
  function gotoinboxDetil(id) {
    history.push({
      pathname: `/inboxDetails/${id}`,
    });
  }

  const logout = async () => {
    dispatch(updateUserInfo({}));
    setShowMenu(false);
    window.location.href = baseurl + '/auth/aad/b2c/logout';
  };
  useEffect(() => {
    // console.log(accessToken, user.accessToken)
    // if (accessToken && (!user.accessToken && !user.userId)) {
    //   handleLogin(accessToken, objectId)
    // }
    if (history.location.pathname === '/' || history.location.pathname === '/activateAccount') {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }, []);
  const LanArrs = [
    { code: 'en_US', title: 'ENG', value: 'en_US' },
    { code: 'zh_HK', title: '繁體', value: 'zh_HK' },
    { code: 'zh_CN', title: '简体', value: 'zh_CN' },
    { code: 'jp', title: '日本语', value: 'jp' },
  ];
  useMemo(() => {
    const searchParams = location.search;
    const params = qs.parse(searchParams, { ignoreQueryPrefix: true });
    const lan = LanArrs.filter((itme) => itme.value == params.lang);
    if (lan && lan.length > 0) {
      localStorage['lang'] = lan[0].code;
      i18n.changeLanguage(lan[0].code);
      setCurrentLocal(lan[0].code);
    } else {
      const lang = localStorage['lang'] ? localStorage['lang'] : 'en_US';
      localStorage['lang'] = lang;
      i18n.changeLanguage(lang);
      setCurrentLocal(lang);
    }
  }, [location]);

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  useEffect(() => {
    if (accessToken && !user.accessToken && !user.userId) {
      return;
    } else {
      init();
    }
  }, [location]);

  // const aa= () =>{
  //   // console.log(accessToken, user.accessToken)
  //   // if (accessToken && (!user.accessToken && !user.userId)) {
  //   //   handleLogin(accessToken, objectId)
  //   // }
  //   if(history.location.pathname === '/'||history.location.pathname === '/activateAccount'){
  //     setShowMenu(false);
  //   } else {
  //     init()
  //     red()
  //     setShowMenu(true);
  //   }
  // }
  return (
    <div className={`${showMobileMenu ? "mobile-header" : ""} Header`}>
      <ErrorDialog />
      <div className="top">
        <div className="main-box d-flex flex-row-reverse">
          <div className="d-flex">
            {LanArrs.map((lan, index) => {
              return (
                <>
                  <div className="language"
                    onClick={() => {
                      localStorage['lang'] = lan.code;
                      i18n.changeLanguage(lan.code);
                      setCurrentLocal(lan.code);
                    }}>{lan.title}</div>
                  <div className="column"></div>
                </>
              );
            })}

            <div className="login-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
              >
                <path
                  d="M15.2124 6.92783H13.2322V5.16495C13.2322 3.13918 11.5664 1.5 9.50765 1.5C7.44892 1.5 5.78308 3.13918 5.78308 5.16495V6.92783H3.80292C3.22145 6.92783 2.74998 7.3299 2.74998 7.84021L2.81285 15.4639C2.81285 16.0361 3.22145 16.5 3.74006 16.5H15.2595C15.7781 16.5 16.1867 16.0361 16.1867 15.4639L16.2496 7.84021C16.2653 7.3299 15.7939 6.92783 15.2124 6.92783ZM11.1735 6.92783H7.84181V5.16495C7.84181 4.26804 8.59615 3.52577 9.50765 3.52577C10.4192 3.52577 11.1735 4.26804 11.1735 5.16495V6.92783Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="login-box">
              {!showMenu ? (
                <div
                  className="login"
                  onClick={() => {
                    goDetail('/');
                    setShowMenu(false);
                  }}
                >
                  {t('Login')}
                </div>
              ) : (
                <div className="login" onClick={() => logout()}>
                  {t('Logout')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="tail">
        <div className="main-box d-flex justify-content-between">
          <div className="logo"
            onClick={(event) => {
              if (!user.accessToken) {
                event.preventDefault();
              } else {
                if (user.sfUserType != 'CL') {
                  goDetail('/viewClient');
                } else {
                  goDetail('/Investments');
                }
              }

            }}>
            <div className='pc-logo'>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="189"
                height="36"
                viewBox="0 0 189 37"
                fill="none"
                color='#fff'
              >
                <path
                  d="M48.2812 0.865433L40.3821 25.7483C40.0634 26.7192 39.8694 27.3295 39.7169 27.9537C39.5922 27.5376 39.4398 27.1076 39.218 26.4834L29.7392 0.865433H24.7642L15.7149 25.041C15.2438 26.428 14.9805 27.1631 14.7449 28.023C14.634 27.5376 14.4816 27.0382 14.2737 26.3309L6.18065 0.851562H0L11.3635 35.4158H17.5442L26.3024 11.5454C26.666 10.5771 26.9805 9.59108 27.2448 8.59106C27.5777 9.83622 27.9755 11.0631 28.4366 12.2666L36.8622 35.4158H42.9043L54.4202 0.865433H48.2812Z"
                  fill="#003448"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M79.5479 27.3851C79.5479 30.6385 79.6036 31.2476 81.6225 31.2476H82.7364L83.8364 31.0538V35.207L82.9732 35.2901L79.339 35.5393C75.8023 35.5393 74.8694 33.8503 74.5353 32.3413C73.8053 33.1817 72.9218 33.8769 71.9315 34.3902C69.3851 35.5961 66.5921 36.198 63.7721 36.1484C52.7722 36.1484 52.2152 29.0741 52.2152 27.6897C52.1484 24.2582 54.5456 21.2644 57.924 20.56C61.3078 19.9867 64.7417 19.7594 68.172 19.8816H74.3403C74.347 19.771 74.347 19.66 74.3403 19.5494C74.3403 17.3897 74.0758 16.1852 72.1821 15.3961C70.57 14.8102 68.8561 14.5513 67.1417 14.6347C65.2469 14.5628 63.356 14.8542 61.5721 15.493C60.5567 15.7877 59.719 16.5045 59.2747 17.4589L59.1076 17.7358L53.538 17.2236L53.7747 16.6144C54.7912 13.8594 56.0722 10.4122 66.7796 10.4122C69.2038 10.4049 71.6203 10.6838 73.9783 11.2429C79.5479 12.7657 79.5479 16.3791 79.5479 19.0233V27.3851ZM74.2707 24.3254H66.3062C64.1528 24.1728 61.9886 24.3455 59.8873 24.8376C58.6197 25.1555 57.7451 26.3072 57.7848 27.6065C57.7848 29.4893 59.4 31.6766 63.967 31.6766C66.5436 31.6913 69.0789 31.0324 71.3188 29.7661C73.1938 28.5923 74.3137 26.528 74.2707 24.3254Z"
                  fill="#003448"
                />
                <path d="M88.249 0.851562V35.4158H93.3969V0.851562H92.9384H88.249Z" fill="#003448" />
                <path
                  d="M106.652 3.05664H101.118V10.9394H97.0737V15.0737H101.118V28.4138C101.118 31.8591 101.217 35.4146 108.141 35.4146L111.75 35.1528L113.154 35.015V29.9711L112.551 30.0813C111.494 30.3104 110.416 30.4351 109.334 30.4534C106.68 30.4534 106.652 29.3234 106.61 27.0908V26.8841C106.61 26.8841 106.61 17.2374 106.61 15.0186H113.253V10.8843H106.652V3.05664Z"
                  fill="#003448"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M114.724 23.6392C114.724 15.9049 121.179 10.4125 130.434 10.4125H130.476C135.671 10.3845 140.166 11.9677 142.966 14.77C145.218 17.0275 146.439 20.1149 146.341 23.303C146.341 30.9812 140.194 36.1514 130.574 36.1514C120.955 36.1514 114.724 31.3735 114.724 23.6392ZM130.77 31.3869C135.699 31.3869 140.698 28.6126 140.698 23.3303V23.3023C140.73 21.307 139.94 19.3863 138.513 17.992C136.231 15.9853 133.241 14.9761 130.21 15.1897C123.363 15.2878 120.283 19.3651 120.283 23.3864C120.283 28.1643 124.497 31.3869 130.77 31.3869Z"
                  fill="#003448"
                />
                <path
                  d="M176.016 14.6894C173.829 11.9129 170.006 10.4135 165.261 10.4135C163.012 10.3849 160.777 10.7853 158.674 11.5936C157.533 12.0031 156.468 12.6038 155.525 13.3705V11.1771H150.023V35.3882H155.525V23.8518C155.525 21.4363 155.525 18.1461 159.65 16.2997C161.192 15.6345 162.857 15.3126 164.533 15.3557C166.665 15.2219 168.778 15.8349 170.515 17.091C172.454 18.6598 172.509 20.5894 172.55 22.6441V35.416H177.969V22.3248C177.969 19.937 177.969 17.216 176.016 14.731"
                  fill="#003448"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M189 15.2015C189.005 12.5633 186.872 10.4198 184.234 10.4121C181.592 10.4121 179.448 12.55 179.44 15.1923C179.44 17.8305 181.577 19.9699 184.215 19.9724C186.854 19.975 188.995 17.8397 189 15.2015ZM184.234 19.1081C186.403 19.0927 188.149 17.3236 188.136 15.1549C188.123 12.9863 186.356 11.2379 184.188 11.2481C182.019 11.2584 180.268 13.0233 180.276 15.192C180.291 17.3647 182.061 19.1158 184.234 19.1081ZM184.499 12.4335H182.38L182.353 17.8826H183.356V15.6528H184.081L185.474 17.8965H186.52L185.126 15.5831C185.501 15.5618 185.852 15.39 186.098 15.1067C186.345 14.8233 186.467 14.4524 186.436 14.078C186.422 13.0328 185.809 12.4335 184.499 12.4335ZM184.123 14.8012H183.398L183.384 13.2683H184.123C184.736 13.2543 185.433 13.2404 185.433 14.0208C185.433 14.8012 184.736 14.8012 184.123 14.8012Z"
                  fill="#003448"
                />
              </svg>
            </div>
            <div className='mobile-logo'>
              <img src={Image.mobileLogo} alt='' />
            </div>
          </div>
          <div className="toggle-box" onClick={() => toggleMenu()}>
            <span className="toggle-btn"></span>
          </div>
          <div className="mobile-menus">
            {showMenu ? (
              <div className="list d-flex">
                <ui className="d-flex list-ui justify-content-end">
                  {user.sfUserType != 'CL' ? (
                    <li className={`${currentPathname === '/viewClient' ? 'active' : ''}`}>
                      <div
                        className="navigation"
                        onClick={(event) => {
                          if (!user.accessToken) {
                            event.preventDefault();
                          } else {
                            goDetail('/viewClient');
                          }
                        }}
                      >
                        {t('View Clients')}
                      </div>
                    </li>
                  ) : (
                    <>
                      <li
                        onClick={(event) => {
                          if (!user.accessToken) {
                            event.preventDefault();
                          } else {
                            goDetail('/Investments');
                          }
                        }}
                        className={`${currentPathname === '/Investments' ? 'active' : ''}`}
                      >
                        <div className={`navigation pointer`}>{t('Investments')}</div>
                      </li>
                      <li
                        onClick={(event) => {
                          if (!user.accessToken) {
                            event.preventDefault();
                          } else {
                            goDetail('/document');
                          }
                        }}
                        className={`${currentPathname === '/document' ? 'active' : ''}`}
                      >
                        <div className="navigation pointer">{t('My Document')}</div>
                      </li>
                    </>
                  )}

                  {/* <li>
                <div className='navigation' onClick={() => { goDetail('/products') }}>Products</div>
              </li> */}

                  <li
                    onClick={(event) => {
                      if (!user.accessToken) {
                        event.preventDefault();
                      } else {
                        goDetail('/profile');
                      }
                    }}
                    className={`${currentPathname === '/profile' ? 'active' : ''}`}
                  >
                    <div className="navigation pointer">{t('Profile')}</div>
                  </li>
                  {/* <li onClick={() => checkbtn()}> */}
                  <li
                    className={`touch ${currentPathname === '/inbox' ? 'active' : ''}`}
                    onClick={(event) => {
                      if (!user.accessToken) {
                        event.preventDefault();
                      } else {
                        goDetail('/inbox');
                      }
                    }}>

                    <div className="navigation pointer">{t('Inbox')}</div>
                    {rednum > 0 ? <div className="nav-new">{rednum}</div> : <div></div>}
                    {/* <div className={(isButton ? 'unfold-box none' : 'unfold-box block')}> */}
                    <div className="unfold-box block">
                      <div></div>
                      <div className="unfold">
                        <p className="unfold-title">{t('Inbox')}</p>

                        {listData.map((item) => (
                          <div
                            className="message-box"
                            onClick={() => {
                              gotoinboxDetil(item.id);
                            }}
                          >
                            {item.isRead ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                              >
                                <circle cx="5" cy="5" r="5" fill="#FFF" />
                              </svg>
                            ) : (
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                >
                                  <circle cx="5" cy="5" r="5" fill="#FFC81C" />
                                </svg>
                              </div>
                            )}
                            <p className="massage-title">{item.title}</p>
                            <div className="time">{item.deliveryDate}</div>
                          </div>
                        ))}

                        <div
                          className="next"
                          onClick={() => {
                            gotoinbox();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M5.69484 8.56004C5.57454 8.43974 5.42177 8.37695 5.25073 8.37695C5.0797 8.37695 4.92693 8.43974 4.80662 8.56004C4.68663 8.68003 4.62354 8.83146 4.62354 9.00049C4.62354 9.16952 4.68663 9.32094 4.80662 9.44094L11.5596 16.1939C11.6795 16.3139 11.831 16.377 12 16.377C12.169 16.377 12.3205 16.3139 12.4404 16.1939L19.1812 9.4523C19.2514 9.39094 19.3037 9.31586 19.3354 9.22974C19.3627 9.15576 19.3765 9.0789 19.3765 9.00049C19.3765 8.92207 19.3627 8.84521 19.3354 8.77124C19.3037 8.68512 19.2514 8.61003 19.1934 8.56004C19.0734 8.44005 18.922 8.37695 18.7529 8.37695C18.5839 8.37695 18.4325 8.44005 18.3126 8.55994L12.0001 14.8653L5.69484 8.56004Z"
                              fill="#003448"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </li>
                </ui>
              </div>
            ) : (
              <></>
            )}
            <div className="mobile-tools">
              <div className="d-flex flex-row-reverse">
                <div className="d-flex tools-list">
                  {LanArrs.map((lan, index) => {
                    return (
                      <>
                        <div className="language"
                          onClick={() => {
                            localStorage['lang'] = lan.code;
                            i18n.changeLanguage(lan.code);
                            setCurrentLocal(lan.code);
                          }}>{lan.title}</div>
                        <div className="column"></div>
                      </>
                    );
                  })}

                </div>
              </div>
              <div className="mobile-logout-box">
                <div className="mobile-logout">


                  <div className="login-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                    >
                      <path
                        d="M15.2124 6.92783H13.2322V5.16495C13.2322 3.13918 11.5664 1.5 9.50765 1.5C7.44892 1.5 5.78308 3.13918 5.78308 5.16495V6.92783H3.80292C3.22145 6.92783 2.74998 7.3299 2.74998 7.84021L2.81285 15.4639C2.81285 16.0361 3.22145 16.5 3.74006 16.5H15.2595C15.7781 16.5 16.1867 16.0361 16.1867 15.4639L16.2496 7.84021C16.2653 7.3299 15.7939 6.92783 15.2124 6.92783ZM11.1735 6.92783H7.84181V5.16495C7.84181 4.26804 8.59615 3.52577 9.50765 3.52577C10.4192 3.52577 11.1735 4.26804 11.1735 5.16495V6.92783Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="login-box">
                    {!showMenu ? (
                      <div
                        className="login"
                        onClick={() => {
                          goDetail('/');
                          setShowMenu(false);
                        }}
                      >
                        {t('Login')}
                      </div>
                    ) : (
                      <div className="login" onClick={() => logout()}>
                        {t('Logout')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Header;
