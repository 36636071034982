import React from 'react';
import { useState, useEffect } from 'react';
import './index.scss'
import { login } from '../../util/request';
import { updateUserInfo, userInfo } from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Input, Form, Table } from 'antd'
import Qs from 'qs';

const FormItem = Form.Item

const UserList = () => {
  const dispatch = useDispatch();
  const user = useSelector(userInfo);

  const history = useHistory();

    //获取azure返回的token和objectId
    const locationState = history.location.search;
  

  var obj = Qs.parse(locationState, { ignoreQueryPrefix: true });
  const accessToken = obj.accessToken;
  const objectId = obj.objectId;


  const handleLogin = async (accessToken, objectId) => {
    const obj = {
      accessToken: accessToken,
      objectId: objectId,
      deviceId: null,
      deviceModel: 'WEB',
      deviceToken: null,
      entry: 'WEB',
      isChina: false,
    };
    const data = await login('/auth/aad/b2c/login', obj);
    if (data) {
      dispatch(updateUserInfo(data));
      
      if (data.sfUserType == "CL") {
        goDetail('/Investments');
      } else {
        goDetail('/viewClient');
      }
    }
  };
  function goDetail(url) {
    console.log(url);
    history.push({
      pathname: url,
    });
  }

  useEffect(() => {
    if (accessToken ) {
      handleLogin(accessToken, objectId);
    }
    // if (user.userType &&user.userType == "CL") {
    //     goDetail('/Investments');
    //   } else {
    //     goDetail('/viewClient');
    //   }
  }, []);

  return (
    <div className='Page' >
    </div>
  );
};
export default UserList;
